export enum AccountType {
  PERSONAL = 'personal',
  CORPORATE = 'corporate',
  INTERNATIONAL = 'international',
}

export enum BuilderElementStatus {
  PUBLISHED = 'published',
  DRAFT = 'draft',
  ARCHIVED = 'archived',
  SCHEDULED = 'scheduled',
}
